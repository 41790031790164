import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';

const Hero = () => {
  return (
    <Box
      sx={{
        backgroundImage: 'url("https://images.unsplash.com/photo-1445543949571-ffc3e0e2f55e")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '70vh',
        minHeight: '500px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: '64px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.6)',
        }
      }}
    >
      <Container maxWidth="md" sx={{ position: 'relative', zIndex: 1 }}>
        <Typography
          variant="h1"
          color="white"
          gutterBottom
          sx={{
            fontWeight: 700,
            fontSize: { xs: '2.5rem', md: '4rem' }
          }}
        >
          New Life Church
        </Typography>
        <Typography
          variant="h5"
          color="white"
          paragraph
          sx={{ mb: 4 }}
        >
          Come be part of our family and grow in faith
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
        >
          Know Our Church
        </Button>
      </Container>
    </Box>
  );
};

export default Hero; 