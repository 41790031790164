import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Box } from '@mui/material';

const Events = () => {
  const events = [
    {
      title: "Easter Celebration",
      date: "April 9, 2024",
      description: "Join us for a special Easter service celebrating the resurrection of Christ.",
      image: "https://images.unsplash.com/photo-1544427920-c49ccfb85579"
    },
    {
      title: "Community Outreach",
      date: "May 1, 2024",
      description: "Serving our community through various charitable activities.",
      image: "https://images.unsplash.com/photo-1593113598332-cd288d649433"
    },
    {
      title: "Summer Bible Camp",
      date: "July 15-20, 2024",
      description: "A week of fun, learning, and spiritual growth for children.",
      image: "https://images.unsplash.com/photo-1526976668912-1a811878dd37"
    }
  ];

  return (
    <Box
      component="section"
      sx={{
        py: 8,
        backgroundColor: '#f5f5f5',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ mb: 6 }}
        >
          Upcoming Events
        </Typography>

        <Grid container spacing={4}>
          {events.map((event, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={event.image}
                  alt={event.title}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {event.title}
                  </Typography>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    {event.date}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {event.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Events; 