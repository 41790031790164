import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Box } from '@mui/material';

const Services = () => {
  const services = [
    {
      title: "Sunday Service",
      time: "10:00 AM",
      description: "Main worship service with contemporary praise and biblical teaching."
    },
    {
      title: "Bible Study",
      time: "Wednesday 7:00 PM",
      description: "In-depth Bible study and discussion groups for all ages."
    },
    {
      title: "Youth Ministry",
      time: "Friday 6:30 PM",
      description: "Dynamic program for teenagers with worship, games, and biblical teaching."
    },
    {
      title: "Children's Ministry",
      time: "Sunday 10:00 AM",
      description: "Fun and engaging Bible lessons for children during Sunday service."
    }
  ];

  return (
    <Box
      component="section"
      sx={{
        py: 8,
        backgroundColor: 'white',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ mb: 6 }}
        >
          Our Services
        </Typography>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {service.title}
                  </Typography>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    {service.time}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services; 